import { React } from "react";
import {
  DateInput,
  required,
  CheckboxGroupInput,
  SelectInput,
  TextInput,
  Title,
  useRedirect,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import BenefitSpecificComponent from "./components/BenefitSpecificComponent";
import { DocumentUploadButton } from "../DocumentTab/DocumentUploadButton";
import { selectionWindows } from "./constants";
import { US_STATES } from "components/Blueprint/SelectChoices";
import { BENEFIT_TYPE } from "components/Blueprint/SelectChoices";

const OfferStartDateInput = () => {
  const { setValue } = useFormContext();

  const setDefaultOfferEnd = (e) => {
    const start = e.target.value;
    const date = new Date(start.replace("-", "/"));
    getSelectionWindow(start);
    date.setFullYear(date.getFullYear() + 1);
    date.setDate(date.getDate() - 1);
    const formattedDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    setValue("offering_end_date", formattedDate);
  };

  const getSelectionWindow = (covStartDate) => {
    if (covStartDate in selectionWindows) {
      setValue(
        "selection_window_start_date",
        selectionWindows[covStartDate].startDate
      );
      setValue(
        "selection_window_end_date",
        selectionWindows[covStartDate].endDate
      );
    }
  };

  return (
    <DateInput
      label="Offering Start Date"
      source="offering_start_date"
      onChange={setDefaultOfferEnd}
      validate={required()}
    />
  );
};

const InsuranceQuoteRequestCreateForm = (props) => {
  const redirect = useRedirect();
  const onSuccess = (id) => {
    redirect("show", "company_insurance_quote_requests", id);
  };

  const uploadRoute = "company_insurance_quote_requests";

  return (
    <div>
      <Title title="Create Insurance Quote Request" />
      <h1>Create Insurance Quote Request</h1>
      <DocumentUploadButton
        uploadRoute={uploadRoute}
        resourceId={""}
        onSuccess={(id) => onSuccess(id)}
      >
        <CheckboxGroupInput
          label="Benefit types"
          source="benefit_types"
          choices={BENEFIT_TYPE}
          defaultValue={["medical"]}
          validate={required()}
        />
        <TextInput
          label="SAGE Company ID"
          source="company_id"
          validate={required()}
        />
        <OfferStartDateInput />
        <DateInput
          label="Offering End Date"
          source="offering_end_date"
          validate={required()}
        />
        <DateInput
          label="Selection Window Start Date"
          source="selection_window_start_date"
          validate={required()}
        />
        <DateInput
          label="Selection Window End Date"
          source="selection_window_end_date"
          validate={required()}
        />
        <TextInput
          label="SIC code"
          source="sic_code"
          validate={required()}
          type="number"
        />
        <SelectInput
          label="State"
          source="state"
          choices={US_STATES}
          validate={required()}
        />
        <TextInput
          label="Zip Code"
          source="zip"
          validate={required()}
          type="number"
        />
        <BenefitSpecificComponent />
        <p>
          Upload census below. Make sure to use{" "}
          <a
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/1DnxM8kcS7BJaIidjRkcGyDwYY-IGRXmDLmuXO88iPrQ/edit?usp=sharing"
            rel="noreferrer"
          >
            this template
          </a>
          .
        </p>
      </DocumentUploadButton>
    </div>
  );
};

const InsuranceQuoteRequestCreate = () => {
  return <InsuranceQuoteRequestCreateForm />;
};

InsuranceQuoteRequestCreate.propTypes = {};

InsuranceQuoteRequestCreate.defaultProps = {};

export default InsuranceQuoteRequestCreate;
