/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import {
  SimpleShowLayout,
  useGetOne,
  useNotify,
  LinearProgress,
} from "react-admin";

import { Attribute } from "../../../../GenericAttributes";

const SubResourceShow = function SubResourceShow(config) {
  const { backend_resource: backendResource } = config;
  const attributes = config.attributes.map((attribute) =>
    Attribute("Show", attribute, "subShowPage")
  );
  const SubResourceShowComponent = function SubResourceShowComponent({
    id,
    meta = {},
    options = {},
  }) {
    const {
      data: subRecord,
      error,
      isLoading,
    } = useGetOne(backendResource, { id, meta }, options);

    const notify = useNotify();

    if (error) {
      notify(error.body.message, { type: "error" });
    }

    if (isLoading) return <LinearProgress />;

    return <SimpleShowLayout record={subRecord}>{attributes}</SimpleShowLayout>;
  };

  return SubResourceShowComponent;
};

export default SubResourceShow;
