import oktaAuth from "./oktaAuth";
import { API_HOST } from "../constants";

const AUTH_STORAGE_LOCATION = "auth";
const CHECKAUTH_URL = `${API_HOST}/api/v0/check_auth`;

const login = async () => {};
const logout = async () => {
  localStorage.removeItem(AUTH_STORAGE_LOCATION);

  if (oktaAuth?.authStateManager?.getAuthState()?.isAuthenticated) {
    return oktaAuth.signOut().then(() => {
      return false;
    });
  }
};

const checkAuth = async ({ signal }) => {
  const identity = localStorage.getItem(AUTH_STORAGE_LOCATION);
  if (identity === null) {
    return Promise.reject("Your session has expired");
  }

  async function makeAuthRequest(retryCount = 0) {
    if (retryCount > 3) {
      return Promise.reject("Your session has expired");
    }

    await new Promise((resolve) => setTimeout(resolve, 250 * retryCount));

    const { token } = JSON.parse(identity);
    const request = new Request(CHECKAUTH_URL, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    });

    try {
      const response = await fetch(request, { signal });
      if (response.ok) {
        return Promise.resolve("Authenticated");
      }

      if (response.status >= 500) {
        return makeAuthRequest(retryCount + 1);
      }

      return Promise.reject("Your session has expired");
    } catch (error) {
      if (error.message.toLowerCase().includes("failed to fetch")) {
        return makeAuthRequest(retryCount + 1);
      }

      return Promise.reject("Your session has expired");
    }
  }

  return makeAuthRequest();
};

const handleCallback = async () => {
  const isAuthenticated =
    oktaAuth?.authStateManager?.getAuthState()?.isAuthenticated;
  if (isAuthenticated) {
    return Promise.resolve("Authenticated");
  }

  if (oktaAuth.token.isLoginRedirect()) {
    return oktaAuth.token
      .parseFromUrl()
      .then(({ tokens }) => {
        oktaAuth.tokenManager.setTokens(tokens);
        return Promise.resolve(tokens);
      })
      .then((tokens) => {
        const { accessToken } = tokens;
        const identity = {
          token: accessToken.accessToken,
        };
        localStorage.setItem(AUTH_STORAGE_LOCATION, JSON.stringify(identity));
      });
  } else {
    return Promise.resolve({ redirectTo: "/" });
  }
};

const checkError = (error) => {
  const { status } = error;
  if (status === 401 || status === 403) {
    return Promise.reject();
  }
  return Promise.resolve();
};

const getIdentity = async () => oktaAuth.getUser();

const SsoAuthProvider = {
  login,
  checkError,
  checkAuth,
  logout,
  getIdentity,
  handleCallback,
  getPermissions: () => Promise.resolve(),
  supportAbortSignal: true,
};

export default SsoAuthProvider;
