import { React } from "react";
import { Button, useNotify, useDataProvider } from "react-admin";
import PropTypes from "prop-types";

const DownloadButton = function DownloadButton({
  url,
  fileName,
  label,
  disabled = false,
}) {
  const notify = useNotify();
  const { download } = useDataProvider();
  const onError = (data) =>
    notify(data?.message || data?.error, { type: "error" });

  const clickHandler = async () => {
    await download(url, fileName, { onError });
  };

  return <Button disabled={disabled} onClick={clickHandler} label={label} />;
};

DownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default DownloadButton;
