/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import PropTypes from "prop-types";

import {
  useDataProvider,
  SaveContextProvider,
  RecordContextProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import { useMutation } from "@tanstack/react-query";

const EventEmitter = function EventEmitter({
  topic,
  eventName,
  record,
  children,
  dataMutation,
}) {
  const mutationMode = "pessimistic";
  const { publish } = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) =>
      publish(topic, eventName, { data: dataMutation(data) }).then(
        () => {
          notify(`Event published: ${eventName}`, { type: "success" });
          refresh();
        },
        (error) => {
          notify(error.message, { type: "error" });
        }
      ),
  });

  return (
    <div>
      <RecordContextProvider value={record}>
        <SaveContextProvider
          value={{ save: mutate, saving: isLoading, mutationMode }}
        >
          {children}
        </SaveContextProvider>
      </RecordContextProvider>
    </div>
  );
};

EventEmitter.propTypes = {
  topic: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  record: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  dataMutation: PropTypes.func,
  children: PropTypes.node.isRequired,
};

EventEmitter.defaultProps = {
  record: {},
  dataMutation: (data) => data,
};

export default EventEmitter;
