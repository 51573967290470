import { stringify } from "query-string";
import simpleRestProvider from "ra-data-simple-rest";
import { HttpClient } from "../HttpClient";
import { API_HOST, BASE_URL } from "../constants";
import { download } from "./DataProviderHelpers";

const baseDataProvider = simpleRestProvider(BASE_URL, HttpClient);

const hasQueryParams = (queryParams) => {
  return !!queryParams && Object.keys(queryParams).length > 0;
};

const DataProvider = {
  ...baseDataProvider,
  // Returns full history for a bitemporal resource
  getHistory: async (resource, options) => {
    const { filter = {}, range = [0, 0], sort = ["id", "ASC"] } = options;
    const queryParams = {
      filter: JSON.stringify(filter),
      range: JSON.stringify(range),
      sort: JSON.stringify(sort),
    };

    const url = `${BASE_URL}/${resource}/history?${stringify(queryParams)}`;
    const { status, headers, body, json } = await HttpClient(url);

    return {
      status,
      headers,
      body,
      data: json,
    };
  },
  // Makes a GET request with optional query params
  getOne: (resource, params) => {
    const { id, meta: queryParams } = params;

    let url;
    hasQueryParams(queryParams)
      ? (url = `${BASE_URL}/${resource}/${id}?${stringify(queryParams)}`)
      : (url = `${BASE_URL}/${resource}/${id}`);

    return HttpClient(url).then(({ json }) => ({
      data: json,
    }));
  },
  // Makes a GET request in multiple requests to avoid URLs that are too long (over 2000 chars)
  // And stitches the responses together to return one total response
  getMany: (resource, params) => {
    const { ids, meta: queryParams } = params;

    const urls = [];
    // Loops through IDs in increments of 100 and creates a URL for that subset
    for (let i = 0; i < ids.length; i += 100) {
      let url;
      const subset = ids.slice(i, i + 100);
      const filter = {
        id: subset,
      };
      const filter_ids = encodeURIComponent(JSON.stringify(filter));
      hasQueryParams(queryParams)
        ? (url = `${BASE_URL}/${resource}?filter=${filter_ids}?${stringify(
            queryParams
          )}`)
        : (url = `${BASE_URL}/${resource}?filter=${filter_ids}`);
      urls.push(url);
    }

    // Makes a list of promises for each of the created URLs
    const promises = urls.map((url) =>
      HttpClient(url).then(({ json }) => json)
    );

    // When the promises are complete, concatenates the responses together and returns them in one object
    return Promise.all(promises)
      .then((responses) => {
        const data = responses.reduce(
          (result, current) => result.concat(current),
          []
        );
        return {
          meta: {
            unit: resource,
            range: [null, null],
            count: data.length,
            total: data.length,
          },
          data: data,
        };
      })
      .catch((error) => {
        return { data: [] };
      });
  },
  // Returns the current status for a process type and owner id
  getProcessStatus: async (processName, ownerId) => {
    const url = `${BASE_URL}/${processName}/${ownerId}/status`;
    const { status, headers, body, json } = await HttpClient(url);

    return {
      status,
      headers,
      body,
      data: json,
    };
  },
  // Triggers a non-REST action
  takeAction: async (resource, { id, action, subAction }, options) => {
    let url = `${BASE_URL}/${resource}`;
    if (id) {
      url += `/${id}`;
    }
    if (action) {
      url += `/${action}`;
    }
    if (subAction) {
      url += `/${subAction}`;
    }
    const { status, headers, body, json } = await HttpClient(url, {
      method: "POST",
      ...options,
    });

    return {
      status,
      headers,
      body,
      data: json,
    };
  },
  // Publishes an event into the event stream
  publish: async (topic, eventName, data) => {
    const url = `${API_HOST}/api/v0/events/${topic}/publish/${eventName}`;
    const { status, headers, body, json } = await HttpClient(url, {
      method: "POST",
      body: JSON.stringify(data),
    });

    return {
      status,
      headers,
      body,
      data: json,
    };
  },
  // Uploads a document
  upload: async ({ formattedUploadData, uploadRoute }) => {
    const url = `${API_HOST}/api/v0/${uploadRoute}/upload`;

    const { status, headers, body, json } = await HttpClient(url, {
      method: "POST",
      body: JSON.stringify(formattedUploadData),
    });

    return {
      status,
      headers,
      body,
      data: json,
    };
  },
  download,
};

export default DataProvider;
