import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthProvider } from "react-admin";
import { Avatar, Box, Card, CircularProgress } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { oktaAuth } from "../../../lib/SsoAuthProvider";

const ssoLogin = async () => {
  await oktaAuth.clearDPoPStorage();
  oktaAuth.signInWithRedirect({
    responseType: "token",
    scopes: ["openid", "profile", "email"],
  });
};

const LoginRedirectPage = () => {
  const navigate = useNavigate();
  const authProvider = useAuthProvider();

  useEffect(() => {
    const asyncLogin = async () => {
      if (!authProvider) {
        navigate("/");
      } else {
        await ssoLogin();
      }
    };
    asyncLogin();
  }, [authProvider, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundImage:
          "radial-gradient(circle at 50% 14em, rgb(49, 50, 100) 0%, rgb(0, 2, 59) 60%, rgb(0, 2, 59) 100%)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Card sx={{ minWidth: 300, marginTop: "6em" }}>
        <Box
          sx={{
            margin: "1em",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Avatar sx={{ bgcolor: "secondary.main" }}>
            <LockIcon />
          </Avatar>
        </Box>
        <Box
          sx={{
            marginTop: "3em",
            display: "flex",
            justifyContent: "center",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CircularProgress />
        </Box>
        <Box
          sx={{
            marginTop: "1em",
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <p>Redirecting to identity provider...</p>
          <p>
            If you are not redirected,&nbsp;
            <a href="/" rel="noopener noreferrer">
              click here
            </a>
          </p>
        </Box>
      </Card>
    </Box>
  );
};

export default LoginRedirectPage;
