import React from "react";

import {
  List,
  Create,
  Datagrid,
  SimpleForm,
  ReferenceArrayField,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextField,
  DateField,
  SingleFieldList,
  ShowButton,
  ChipField,
  SaveButton,
  useRefresh,
  useNotify,
} from "react-admin";

import { useFormContext } from "react-hook-form";
import { Typography, Grid } from "@mui/material";

import { BENEFIT_TYPE } from "../../../../SelectChoices";

const BenefitOfferingList = function BenefitOfferingList({
  companyId,
  planYearId,
  benefitCycleId,
}) {
  return (
    <div>
      <h4>Benefit Offerings:</h4>
      <List
        resource="company_benefit_offerings"
        filter={{
          company_id: companyId,
          plan_year_id: planYearId,
          company_benefit_cycle_id: benefitCycleId,
        }}
        actions={false}
        perPage={5}
        disableSyncWithLocation={true}
        empty={
          <Typography variant="h5">
            No benefit offerings for this plan year and cycle.
          </Typography>
        }
      >
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField label="Benefit Type" source="benefit_type" />
          <ReferenceArrayField
            label="Providers"
            source="provider_ids"
            reference="providers"
          >
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <DateField label="Offering Start Date" source="offering_start_date" />
          <DateField label="Offering End Date" source="offering_end_date" />
          <ShowButton />
        </Datagrid>
      </List>
    </div>
  );
};

const SaveAndEmptyFormButton = function SaveAndEmptyFormButton() {
  const { reset } = useFormContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const onSuccess = (data) => {
    reset();
    notify("Created", { type: "success" });
    refresh();
  };

  const onError = (error) => {
    reset();
    notify(error.message, { type: "error" });
    refresh();
  };

  return <SaveButton type="button" mutationOptions={{ onSuccess, onError }} />;
};

const BenefitOfferingCreateForm = function BenefitOfferingCreateForm({
  companyId,
  planYearId,
  benefitCycleId,
  offeringStartDate,
  offeringEndDate,
  companyBenefitSelectionWindowId,
}) {
  return (
    <Create
      resource="company_benefit_offerings"
      actions={false}
      redirect={false}
    >
      <SimpleForm
        toolbar={false}
        defaultValues={{
          company_id: companyId,
          plan_year_id: planYearId,
          company_benefit_cycle_id: benefitCycleId,
          offering_start_date: offeringStartDate,
          offering_end_date: offeringEndDate,
          company_benefit_selection_window_id: companyBenefitSelectionWindowId,
        }}
      >
        <Typography>Add a benefit offering:</Typography>
        <Grid container>
          <Grid item sx={{ marginRight: "10px", marginTop: "10px" }}>
            <SelectInput
              source="benefit_type"
              choices={BENEFIT_TYPE}
              helperText={false}
            />
          </Grid>
          <Grid item sx={{ marginRight: "10px" }}>
            <ReferenceArrayInput
              source="provider_ids"
              reference="providers"
              perpage={10000}
              sort={{ field: "id", order: "ASC" }}
            >
              <SelectArrayInput
                label="Providers"
                source="name"
                row={false}
                sx={{ marginTop: "10px" }}
              />
            </ReferenceArrayInput>
          </Grid>
          <Grid item sx={{ marginRight: "10px", marginTop: "19px" }}>
            <SaveAndEmptyFormButton />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const CreateBenefitOfferingsPrompt = function CreateBenefitOfferingsPrompt({
  data,
}) {
  const {
    company_id: companyId,
    plan_year_id: planYearId,
    company_benefit_cycle_id: benefitCycleId,
    offering_end_date: offeringEndDate,
    offering_start_date: offeringStartDate,
    company_benefit_selection_window_id: companyBenefitSelectionWindowId,
  } = data;

  return (
    <div>
      <BenefitOfferingList
        companyId={companyId}
        planYearId={planYearId}
        benefitCycleId={benefitCycleId}
      />
      <BenefitOfferingCreateForm
        companyId={companyId}
        planYearId={planYearId}
        benefitCycleId={benefitCycleId}
        offeringStartDate={offeringStartDate}
        offeringEndDate={offeringEndDate}
        companyBenefitSelectionWindowId={companyBenefitSelectionWindowId}
      />
    </div>
  );
};

export default CreateBenefitOfferingsPrompt;
