import { oktaAuth } from "lib/SsoAuthProvider";

const {
  REACT_APP_INTERNAL_TOOLS_ENABLE_SSO,
  REACT_APP_INTERNAL_TOOLS_ENABLE_AUTHENTICATION,
} = process.env;

const download = async (url, fileName, requestOptions) => {
  let token;

  const useSSO = window.localStorage.getItem("use_sso") === "true";
  const authEnabled =
    REACT_APP_INTERNAL_TOOLS_ENABLE_AUTHENTICATION !== "false" || useSSO; // Default to true if unset
  const ssoEnabled = REACT_APP_INTERNAL_TOOLS_ENABLE_SSO === "true" || useSSO; // Default to false if unset

  if (ssoEnabled && authEnabled) {
    const { accessToken } = await oktaAuth.tokenManager.get("accessToken");
    token = `Bearer ${accessToken}`;
  } else if (authEnabled) {
    token = JSON.parse(localStorage.getItem("auth")).token;
  }

  const { onError, onSuccess, ...otherOptions } = requestOptions;

  const options = { credentials: "include", ...otherOptions };

  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  } else {
    options.headers = new Headers(options.headers);
  }
  options.headers.set("Content-Type", "application/json");
  options.headers.set("Authorization", token);

  try {
    const response = await fetch(url, options);
    if (response.ok) {
      const file = await response.blob();
      const objectUrl = document.createElement("a");

      objectUrl.href = URL.createObjectURL(file);
      objectUrl.setAttribute("download", fileName);
      objectUrl.click();
      if (onSuccess) {
        onSuccess();
      }
    } else {
      const data = await response.json();
      if (onError) {
        onError(data);
      }
    }
  } catch (error) {
    return Promise.reject(`Error - ${error}`);
  }
};

export { download };
