import * as React from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../ProcessManager";

const NewHireMedicalInsuranceComponent =
  function NewHireMedicalInsuranceComponent(props) {
    const recordContext = useRecordContext(props);
    const memberBenefitSelectionId = recordContext.id;
    const memberBenefitSelectionStatus = recordContext.selection_status;
    const readyToEnroll = recordContext.ready_to_enroll;

    if (
      (memberBenefitSelectionStatus === "confirmed" && readyToEnroll) ||
      memberBenefitSelectionStatus === "enrolled"
    ) {
      return (
        <ProcessManager
          processName="new_hire_medical_insurance_enrollment"
          ownerId={memberBenefitSelectionId}
          kickoffButtonLabel="Sync to Noyo"
        />
      );
    } else if (memberBenefitSelectionStatus === "confirmed") {
      return (
        <span style={{ fontSize: "0.875rem", color: "red" }}>
          Please complete enrollment prerequisites before syncing with Noyo.
        </span>
      );
    }

    return null;
  };

const NewHireMedicalInsuranceManagerCondition =
  function NewHireMedicalInsuranceManagerCondition() {
    const recordContext = useRecordContext();
    const noyoMemberRequestEnabled = recordContext.noyo_member_request_enabled;

    if (noyoMemberRequestEnabled === false) {
      return "";
    } else {
      return (
        <NewHireMedicalInsuranceComponent
          label="Selection Noyo Sync"
          key="NewHireMedicalInsuranceComponent"
        />
      );
    }
  };

const NewHireMedicalInsuranceManager =
  function NewHireMedicalInsuranceManager() {
    return <NewHireMedicalInsuranceManagerCondition />;
  };

export default NewHireMedicalInsuranceManager;
