import { React, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import PropTypes from "prop-types";
import {
  useDataProvider,
  useNotify,
  SaveContextProvider,
  RecordContextProvider,
  SimpleForm,
} from "react-admin";

const DocumentUploadButton = function DocumentUploadButton({
  uploadRoute,
  resourceId,
  children,
  onSuccess,
}) {
  const mutationMode = "pessimistic";

  const [file, setFile] = useState();
  const [dataUrl, setDataUrl] = useState();

  const onFileUpload = (uploadEvent) => {
    const currentFile = uploadEvent.target.files[0];
    setFile(currentFile);

    const reader = new FileReader();
    reader.onload = (event) => {
      setDataUrl(event.target.result);
    };

    reader.readAsDataURL(currentFile);
  };

  const { upload } = useDataProvider();
  const notify = useNotify();

  const { mutate, isLoading } = useMutation({
    mutationFn: (formRecord) => {
      const formattedUploadData = {
        content_type: file.type,
        file_name: file.name,
        data_url: dataUrl,
        resource_id: resourceId,
        ...formRecord,
      };

      upload({ formattedUploadData, uploadRoute }).then(
        (response) => {
          notify("Document uploaded", { type: "success" });
          onSuccess && onSuccess(response.data.id);
        },
        (error) => {
          notify(error.body.error, { type: "error" });
        }
      );
    },
  });

  return (
    <div>
      <RecordContextProvider>
        <SaveContextProvider
          value={{ save: mutate, saving: isLoading, mutationMode }}
        >
          <SimpleForm>
            {children}
            <input type="file" onChange={onFileUpload} />
          </SimpleForm>
        </SaveContextProvider>
      </RecordContextProvider>
    </div>
  );
};

DocumentUploadButton.propTypes = {
  uploadRoute: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSuccess: PropTypes.func,
};

export default DocumentUploadButton;
