import { React, useState, useEffect, useCallback } from "react";
import { useRecordContext } from "react-admin";
import EntityTable from "./EntityTable";
import EntityForm from "./EntityForm";
import Dropdown from "./Dropdown";
import { Box } from "@mui/material";
import { BASE_URL } from "lib/constants";
import { tableForSchema } from "./schemaHelpers";
import { HttpClient } from "lib/HttpClient";

const InternationalCompanyEnrollmentsTabComponent = (props) => {
  const canonicalCompanyId = useRecordContext(props).cdms_canonical_id;
  const [state, setState] = useState({
    error: null,
    validBenefitTypes: [],
    selectedBenefitType: "",
    companyEnrollmentsSchema: null,
    companyEnrollments: null,
    companyOfferings: null,
  });
  const [table, setTable] = useState([]);
  const benefitTypesUrl = `${BASE_URL}/international/benefits`;
  const schemaUrl = `${BASE_URL}/international/${state.selectedBenefitType}/company_enrollments/schema`;
  const companyEnrollmentsUrl = `${BASE_URL}/international/${state.selectedBenefitType}/company_enrollments?company_id=${canonicalCompanyId}`;
  const companyOfferingsUrl = `${BASE_URL}/international/${state.selectedBenefitType}/company_offerings?company_id=${canonicalCompanyId}`;

  const updateState = useCallback(
    (key, result) => {
      setState((prevState) => ({ ...prevState, [key]: result }));
    },
    [setState]
  );

  const setSelectedBenefitType = (event) => {
    updateState("selectedBenefitType", event.target.value);
  };

  const fetchDataCallback = useCallback(
    async (url, key) => {
      const { json } = await HttpClient(url);
      updateState(key, json);
    },
    [updateState]
  );

  function handleSubmit() {
    fetchDataCallback(companyEnrollmentsUrl, "companyEnrollments");
  }

  // Fetch benefit types
  useEffect(() => {
    fetchDataCallback(benefitTypesUrl, "validBenefitTypes");
  }, [fetchDataCallback, benefitTypesUrl]);

  // Fetch schema, company enrollments, and company offerings
  useEffect(() => {
    if (state.selectedBenefitType) {
      fetchDataCallback(schemaUrl, "companyEnrollmentsSchema");
      fetchDataCallback(companyEnrollmentsUrl, "companyEnrollments");
    }
    if (state.selectedBenefitType === "health") {
      fetchDataCallback(companyOfferingsUrl, "companyOfferings");
    }
  }, [
    fetchDataCallback,
    schemaUrl,
    companyEnrollmentsUrl,
    companyOfferingsUrl,
    state.selectedBenefitType,
  ]);

  // Fetch table
  useEffect(() => {
    const propertiesToIgnoreInList = [
      "plans_id",
      "company_id",
      "external_ids",
      "created_at",
      "updated_at",
      `company_${state.selectedBenefitType}_offering_id`,
    ];
    async function getTable() {
      if (state.companyEnrollmentsSchema && state.companyEnrollments) {
        setTable(
          tableForSchema(
            state.companyEnrollmentsSchema,
            state.companyEnrollments,
            propertiesToIgnoreInList
          )
        );
      }
    }
    getTable();
  }, [
    state.companyEnrollments,
    state.companyEnrollmentsSchema,
    state.selectedBenefitType,
  ]);

  const propertiesToIgnoreInCreate = [
    "id",
    "external_ids",
    "created_at",
    "updated_at",
  ];

  const presetFormValues = () => {
    const presetValuesHash = { company_id: canonicalCompanyId };
    if (state.companyOfferings?.length > 0) {
      presetValuesHash[`company_${state.selectedBenefitType}_offering_id`] =
        state.companyOfferings[0].id;
    }
    return presetValuesHash;
  };

  const hasFormDependencies =
    state.selectedBenefitType === "health"
      ? state.companyEnrollmentsSchema && state.companyOfferings?.length >= 0
      : state.companyEnrollmentsSchema;

  return (
    <Box sx={{ margin: [2, 0, 0, 2] }}>
      <Dropdown
        label="Benefit Type"
        selected={state.selectedBenefitType}
        setSelected={setSelectedBenefitType}
        options={state.validBenefitTypes}
        sx={{ width: 200 }}
      />
      {hasFormDependencies ? (
        <EntityForm
          presetFormValues={presetFormValues()}
          schema={state.companyEnrollmentsSchema || {}}
          propertiesToIgnore={propertiesToIgnoreInCreate}
          baseApiUrl={BASE_URL + "/international"}
          createUrl={companyEnrollmentsUrl}
          onSuccess={handleSubmit}
          sx={{ width: 300 }}
        />
      ) : (
        <></>
      )}
      {table.length > 0 ? <EntityTable table={table} /> : <></>}
    </Box>
  );
};

const InternationalCompanyEnrollmentsTab = () => {
  return <InternationalCompanyEnrollmentsTabComponent />;
};

export default InternationalCompanyEnrollmentsTab;
