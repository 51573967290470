import oktaConfig from "./oktaConfig";
import { OktaAuth } from "@okta/okta-auth-js";

const randomState = () => Math.random().toString(36).slice(2);
let state = window.sessionStorage.getItem("login_state_reference");

if (!state) {
  state = randomState();
  window.sessionStorage.setItem("login_state_reference", state);
}

const oktaAuth = new OktaAuth({ state, ...oktaConfig });
oktaAuth.start();

export default oktaAuth;
