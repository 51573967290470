const {
  REACT_APP_INTERNAL_TOOLS_OKTA_CLIENT_ID,
  REACT_APP_INTERNAL_TOOLS_OKTA_ISSUER,
  REACT_APP_INTERNAL_TOOLS_OKTA_REDIRECT_URI,
} = process.env;

export default {
  clientId: REACT_APP_INTERNAL_TOOLS_OKTA_CLIENT_ID || "0oa1q1z5z5z5z5z5z5z5",
  issuer:
    REACT_APP_INTERNAL_TOOLS_OKTA_ISSUER ||
    "https://dev-123456.okta.com/oauth2/default",
  redirectUri:
    REACT_APP_INTERNAL_TOOLS_OKTA_REDIRECT_URI || "http://localhost:3000",
  postLogoutRedirectUri: "https://tools.benefits.secure.justworks.com",
  scopes: ["openid", "profile", "email"],
  pkce: true,
};
