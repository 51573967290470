import * as React from "react";
import { useRecordContext } from "react-admin";
import { ProcessManager } from "../ProcessManager";

const MemberSetupManagerComponent = function MemberSetupManagerComponent(
  props
) {
  const memberId = useRecordContext(props).id;

  return (
    <ProcessManager
      processName="member_setup"
      ownerId={memberId}
      kickoffButtonLabel="Sync member"
    />
  );
};

const MemberSetupManagerCondition = function MemberSetupManagerCondition() {
  const recordContext = useRecordContext();
  const memberId = recordContext.id;
  const noyoMemberRequestEnabled = recordContext.noyo_member_request_enabled;

  if (noyoMemberRequestEnabled === false) {
    return "";
  } else {
    return (
      <MemberSetupManagerComponent
        label="Member Noyo Sync"
        key="MemberSetupManagerComponent"
        memberId={memberId}
      />
    );
  }
};

const MemberSetupManager = function MemberSetupManager() {
  return <MemberSetupManagerCondition />;
};

export default MemberSetupManager;
