import { oktaAuth } from "../SsoAuthProvider/";

const refreshAuthTokens = async (refreshToken) => {
  const renewToken = await oktaAuth.token.renew(refreshToken);
  await oktaAuth.tokenManager.setTokens(renewToken);
};

const getAuthTokensFromLocalStorage = () => {
  const tokenStore = localStorage.getItem("okta-token-storage");
  if (!tokenStore) {
    return {};
  }

  const { accessToken, refreshToken } = tokenStore;
  return { accessToken, refreshToken };
};

const refreshAuth = () => {
  const { accessToken, refreshToken } = getAuthTokensFromLocalStorage();
  if (accessToken && accessToken.expiresAt < Date.now() / 1000) {
    return refreshAuthTokens(refreshToken);
  }
  return Promise.resolve();
};

export default refreshAuth;
