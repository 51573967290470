import React from "react";
import {
  Datagrid,
  TextField,
  ReferenceField,
  List,
  useList,
  ListContextProvider,
} from "react-admin";
import { ConvertSelectionButton } from "../../../ConvertSelectionButton";
import FullNameLink from "./FullNameLink";
import { BulkEnrollButton } from "../../../BulkEnrollButton";

const sort = { field: "last_name", order: "ASC" };
const isRowSelectable = (record) => record.selection_status === "confirmed";

const CustomActionDataGrid = ({ selectionsToEnroll }) => {
  const bulkActionButtons = <BulkEnrollButton />;
  return (
    <Datagrid
      data={selectionsToEnroll}
      sort={sort}
      isRowSelectable={isRowSelectable}
      bulkActionButtons={bulkActionButtons}
      rowClick={false}
    >
      <FullNameLink source="member_id" reference="members" label="Member" />
      <TextField source="selection_status" />
      <ReferenceField
        source="company_plan_offering_id"
        reference="company_plan_offerings"
        label="Plan"
        link="show"
      >
        <TextField source="plan_name" />
      </ReferenceField>
      <ReferenceField
        source="previous_company_plan_offering_id"
        reference="company_plan_offerings"
        label="Previous Plan"
        link="show"
        emptyText="No previous plan found"
      >
        <TextField source="plan_name" />
      </ReferenceField>
      <TextField
        source="incomplete_enrollment_prerequisites"
        label="Incomplete Prerequisites"
      />
      <ConvertSelectionButton label="Manual Enroll" />
      <TextField source="coverage_tier" label="Enrollment Tier" />
      <ReferenceField
        source="company_plan_offering_id"
        reference="company_plan_offerings"
        label="Plan Code"
        link="show"
      >
        <TextField source="plan_code" />
      </ReferenceField>
    </Datagrid>
  );
};

const WaitingForEnrollmentCreationPrompt = ({ data }) => {
  const {
    selections_to_enroll: selectionsToEnroll,
    enrolled_selections: enrolledSelections,
    declined_selections: declinedSelections,
  } = data;

  const listContext = useList({ data: selectionsToEnroll, isPending: false });

  return (
    <div>
      {selectionsToEnroll[0] && (
        <div>
          <h4>Selections pending enrollment:</h4>
          <List pagination={false}>
            <ListContextProvider value={listContext}>
              <CustomActionDataGrid
                selectionsToEnroll={selectionsToEnroll}
                sort={sort}
                isRowSelectable={isRowSelectable}
              />
            </ListContextProvider>
            ;
          </List>
        </div>
      )}
      {enrolledSelections[0] && (
        <div>
          <h4>Selections with completed enrollments:</h4>
          <Datagrid
            data={enrolledSelections}
            sort={sort}
            bulkActionButtons={false}
            rowClick={false}
          >
            <FullNameLink
              source="member_id"
              reference="members"
              label="Member"
            />
            <ReferenceField
              source="company_plan_offering_id"
              reference="company_plan_offerings"
              label="Plan"
              link="show"
            >
              <TextField source="plan_name" />
            </ReferenceField>
            <TextField source="selection_status" />
          </Datagrid>
        </div>
      )}
      {declinedSelections[0] && (
        <div>
          <h4>Declined Selections:</h4>
          <Datagrid
            data={declinedSelections}
            sort={sort}
            bulkActionButtons={false}
            rowClick={false}
          >
            <FullNameLink
              source="member_id"
              reference="members"
              label="Member"
            />
            <TextField source="selection_status" />
          </Datagrid>
        </div>
      )}
    </div>
  );
};

export default WaitingForEnrollmentCreationPrompt;
