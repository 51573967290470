import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CircularProgress } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthProvider } from "react-admin";

const AuthCallbackPage = () => {
  const [authenticating, setAuthenticating] = useState(false);
  const authProvider = useAuthProvider();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthentication = async () => {
      if (authenticating) return;
      await setAuthenticating(true);
      await authProvider.handleCallback();
      navigate("/");
    };

    handleAuthentication();
  }, [authProvider, location, navigate, authenticating]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundImage:
          "radial-gradient(circle at 50% 14em, rgb(49, 50, 100) 0%, rgb(0, 2, 59) 60%, rgb(0, 2, 59) 100%)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Card sx={{ minWidth: 300, marginTop: "6em" }}>
        <Box
          sx={{
            margin: "1em",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Avatar sx={{ bgcolor: "secondary.main" }}>
            <LockIcon />
          </Avatar>
        </Box>
        <Box
          sx={{
            marginTop: "3em",
            display: "flex",
            justifyContent: "center",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CircularProgress />
        </Box>
        <Box
          sx={{
            marginTop: "1em",
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <p>Authenticating</p>
        </Box>
      </Card>
    </Box>
  );
};

export default AuthCallbackPage;
