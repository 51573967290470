/* eslint-disable default-param-last */
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDataProvider } from "react-admin";

const useGetHistory = (resource, params = {}, options) => {
  const {
    pagination = { page: 1, perPage: 25 },
    sort = { field: "id", order: "DESC" },
    filter = {},
    meta,
  } = params;
  const dataProvider = useDataProvider();
  const result = useQuery({
    queryKey: [resource, "getHistory", pagination, sort, filter, meta],
    queryFn: () =>
      dataProvider
        .getHistory(resource, {
          pagination,
          sort,
          filter,
          meta,
        })
        .then(({ data, total, pageInfo }) => ({
          data,
          total,
          pageInfo,
        })),
    options: {
      ...options,
      onSuccess: (value) => {
        // execute call-time onSuccess if provided
        if (options?.onSuccess) {
          options.onSuccess(value);
        }
      },
    },
  });

  return useMemo(
    () =>
      result.data
        ? {
            ...result,
            data: result.data?.data,
            total: result.data?.total,
            pageInfo: result.data?.pageInfo,
          }
        : result,
    [result]
  );
};

export default useGetHistory;
