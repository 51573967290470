/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import {
  useRecordContext,
  SelectInput,
  RecordContextProvider,
  useAugmentedForm,
  FormGroupsProvider,
  required,
} from "react-admin";
import { FormProvider } from "react-hook-form";
import { HistoryEdit, HistoryList, HistoryShowButton } from "./components";

const SubResourceHistory = function SubResourceHistory(config) {
  const {
    linking_field: reference,
    timeline_identifiers: timelineIdentifiers,
    read_only: readOnly,
  } = config;

  const HistoryEditComponent = HistoryEdit(config);
  const HistoryListComponent = HistoryList(config);
  const HistoryShowButtonComponent = HistoryShowButton(config);

  const SelectedTimelineListComponent = function SelectedTimelineListComponent({
    parentId,
  }) {
    const selectedTimeLineIdentifiers = useRecordContext();
    const timelineIdentifiers = {
      [reference]: parentId,
      ...selectedTimeLineIdentifiers,
    };
    const labelKeys = Object.keys(selectedTimeLineIdentifiers);

    return (
      <HistoryListComponent
        timelineIdentifiers={timelineIdentifiers}
        labelKeys={labelKeys}
      />
    );
  };

  const TimelineIdentifierSelect = function TimelineIdentifierSelect({
    selectedTimelineIdentifiers,
    updateSelectedTimelineIdentifiers,
    timelineIdentifier,
  }) {
    const { name, label, choices } = timelineIdentifier;

    const handleChange = function handleChange({ target }) {
      const { value } = target;
      updateSelectedTimelineIdentifiers({
        ...selectedTimelineIdentifiers,
        [name]: value,
      });
    };

    return (
      <SelectInput
        source={name}
        label={label}
        choices={choices}
        onChange={handleChange}
        validate={required()}
      />
    );
  };

  const defaultTimelineIdentifier = function defaultTimelineIdentifier(
    timelineIdentifiers
  ) {
    const defaultValues = {};

    timelineIdentifiers &&
      timelineIdentifiers.forEach((timelineIdentifier) => {
        defaultValues[timelineIdentifier.name] =
          timelineIdentifier.choices[0].id;
      });

    return defaultValues;
  };

  const countTimelinePermutations = function countTimelinePermutations(
    timelineIdentifiers
  ) {
    if (!timelineIdentifiers) return 0;

    return timelineIdentifiers.reduce(
      (accumulator, identifier) => accumulator + identifier.choices.length,
      0
    );
  };

  const SubResourceHistoryComponent = function SubResourceHistoryComponent() {
    const parentId = useRecordContext().id;
    const defaultValues = defaultTimelineIdentifier(timelineIdentifiers);
    const timelinePermutations = countTimelinePermutations(timelineIdentifiers);
    const { form } = useAugmentedForm({
      defaultValues,
    });

    const [selectedTimelineIdentifiers, updateSelectedTimelineIdentifiers] =
      useState(defaultValues);

    return (
      <div>
        <RecordContextProvider value={selectedTimelineIdentifiers}>
          <FormProvider {...form}>
            <FormGroupsProvider>
              {timelinePermutations > 1 &&
                timelineIdentifiers.map((timelineIdentifier) => (
                  <TimelineIdentifierSelect
                    key={timelineIdentifier.name}
                    timelineIdentifier={timelineIdentifier}
                    selectedTimelineIdentifiers={selectedTimelineIdentifiers}
                    updateSelectedTimelineIdentifiers={
                      updateSelectedTimelineIdentifiers
                    }
                  />
                ))}
              <HistoryShowButtonComponent parentId={parentId} />
              <SelectedTimelineListComponent parentId={parentId} />
              {!readOnly && <HistoryEditComponent parentId={parentId} />}
            </FormGroupsProvider>
          </FormProvider>
        </RecordContextProvider>
      </div>
    );
  };

  return SubResourceHistoryComponent;
};

export default SubResourceHistory;
