import { React, useState } from "react";

import {
  Button,
  useNotify,
  useDataProvider,
  useRefresh,
  AutocompleteInput,
  RecordContextProvider,
  SaveContextProvider,
  SimpleForm,
  AutocompleteArrayInput,
} from "react-admin";

import { useMutation } from "@tanstack/react-query";
import { useRecordContext } from "react-admin";

import { Grid } from "@mui/material";

const SageToNoyoPlanMapperComponent = function SageToNoyoPlanMapperComponent({
  planName,
  planId,
  zywaveChoices,
}) {
  const inputStyle = { marginBottom: "5px" };

  return (
    <Grid container direction="row" sx={{ width: "100%" }}>
      <Grid item sx={{ width: "45%", marginRight: "10px" }}>
        <p>{planName}</p>
      </Grid>
      <Grid item2 sx={{ width: "45%" }}>
        <AutocompleteInput
          key="zywavePlan"
          label="Zywave Plans"
          source={`zywave.${planId}`}
          choices={zywaveChoices}
          helperText="To skip this plan, leave me blank"
          sx={inputStyle}
        />
      </Grid>
    </Grid>
  );
};

const MapPlansToZywavePrompt = function MapPlansToZywavePrompt({ data }) {
  const {
    zywave_quote_plans: zywaveQuotePlans,
    available_medical_plans: availableSagePlans,
  } = data;

  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { takeAction } = useDataProvider();

  const mutationMode = "pessimistic";
  const inputStyle = { marginBottom: "5px" };

  const [additionalPlans, setAdditionalPlans] = useState([]);

  const onAddAdditionalPlans = (formValues) => {
    const additionalPlanIds = formValues.additional_plans;
    setAdditionalPlans(
      availableSagePlans.filter((plan) => additionalPlanIds.includes(plan.id))
    );
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (formRecord) => {
      takeAction(
        "company_insurance_quote_requests",
        { id: record.owner_id, action: "map_zywave_plans" },
        {
          body: JSON.stringify({
            plan_mappings: formRecord.zywave,
            workflow_run_id: record.id,
          }),
        }
      ).then(
        () => {
          refresh();
          notify("Plans mapped!", { type: "success" });
        },
        (error) => {
          notify(error.body.error, { type: "error", autoHideDuration: 10000 });
        }
      );
    },
  });

  return (
    <div>
      <RecordContextProvider>
        <SaveContextProvider
          value={{ save: mutate, saving: isLoading, mutationMode }}
        >
          <SimpleForm toolbar={false} id="plan_mappings_form">
            <h4> Map Plans</h4>
            <p>
              Not seeing the plans you expect? Go to the quote url on the right
              hand side and hit "Check for additional plans".
            </p>
            {additionalPlans[0] &&
              additionalPlans.map((plan) => (
                <SageToNoyoPlanMapperComponent
                  planName={plan.name}
                  planId={plan.id}
                  zywaveChoices={zywaveQuotePlans}
                />
              ))}
          </SimpleForm>
          <SimpleForm toolbar={false} onSubmit={onAddAdditionalPlans}>
            <Grid item sx={{ width: "45%", marginRight: "5px" }}>
              <AutocompleteArrayInput
                key="additionalSagePlans"
                label="Sage Plan"
                source="additional_plans"
                choices={availableSagePlans}
                helperText="Select additional plans and then hit add. Then map them above."
                sx={inputStyle}
              />
              <Button
                type="submit"
                sx={{ marginBottom: "20px" }}
                label={"Add Additional Plans"}
              />
            </Grid>
          </SimpleForm>
          <Button
            type="submit"
            sx={{ fontWeight: "bold", marginTop: "20px" }}
            label={"Submit All"}
            form="plan_mappings_form"
          />
        </SaveContextProvider>
      </RecordContextProvider>
    </div>
  );
};

export default MapPlansToZywavePrompt;
