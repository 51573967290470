import * as React from "react";
import { Admin } from "react-admin";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { DataProvider } from "./lib/DataProvider";
import { AuthProvider } from "./lib/AuthProvider";
import { SsoDataProvider } from "./lib/DataProvider";
import { SsoAuthProvider } from "lib/SsoAuthProvider";
import { Blueprint } from "./components/Blueprint";
import { CustomLayout } from "components/Layout";
import { currentTheme } from "lib/themes";
import { ThemeProvider } from "@mui/material/styles";
import { LoginRedirectPage, AuthCallbackPage } from "components/Layout";

import "./App.css";

const {
  REACT_APP_INTERNAL_TOOLS_ENABLE_SSO,
  REACT_APP_INTERNAL_TOOLS_ENABLE_AUTHENTICATION,
} = process.env;

const useSSOParam =
  new URLSearchParams(window.location.search).get("use_sso") || "";
const useSSOLocalStorage = window.localStorage.getItem("use_sso");
if (useSSOParam && useSSOLocalStorage !== useSSOParam) {
  window.localStorage.setItem("use_sso", useSSOParam);
}
const useSSO = useSSOParam === "true" || useSSOLocalStorage === "true";

const authEnabled =
  REACT_APP_INTERNAL_TOOLS_ENABLE_AUTHENTICATION !== "false" || useSSO; // Default to true if unset
const ssoEnabled = REACT_APP_INTERNAL_TOOLS_ENABLE_SSO === "true" || useSSO; // Default to false if unset

const dataProvider = ssoEnabled ? SsoDataProvider : DataProvider;
let authProvider = false;
if (authEnabled) {
  authProvider = ssoEnabled ? SsoAuthProvider : AuthProvider;
}
const loginPage = ssoEnabled ? LoginRedirectPage : undefined;
const authCallbackPage = ssoEnabled ? AuthCallbackPage : undefined;

const App = () => {
  const router = createBrowserRouter([
    {
      path: "*",
      element: (
        <ThemeProvider theme={currentTheme}>
          <Admin
            theme={currentTheme}
            layout={CustomLayout}
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={loginPage}
            authCallbackPage={authCallbackPage}
            requireAuth={authEnabled}
          >
            {/* Automatically Created Resources */}
            {Blueprint()}
          </Admin>
        </ThemeProvider>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
