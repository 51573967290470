import { React } from "react";
import PropTypes from "prop-types";
import { Datagrid, FunctionField, NumberField } from "react-admin";

const sort = { field: "id", order: "DESC" };

const CompositeDataGrid = function ({ rates }) {
  return (
    <Datagrid
      data={[rates]}
      sort={sort}
      bulkActionButtons={false}
      rowClick={false}
    >
      <FunctionField label="Rate Type" render={() => "Composite"} />
      {Object.keys(rates).map((key) => (
        <NumberField
          source={key}
          key={key}
          options={{ style: "currency", currency: "USD" }}
        />
      ))}
    </Datagrid>
  );
};

CompositeDataGrid.propTypes = {
  rates: PropTypes.objectOf(PropTypes.number).isRequired,
};

export default CompositeDataGrid;
