/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Show, TabbedShowLayout, Tab, TabbedShowLayoutTabs } from "react-admin";
import { Attribute } from "../GenericAttributes";
import { SubResourceTab } from "./SubResource";
import ShowTitle from "./ShowTitle";
import { ParentRecordContextProvider } from "../contexts";
import { ExternalIdMappingTab } from "./ExternalIdMappingTab";

const SubResourceTabComponents = function SubResourceTabComponents(
  configs,
  props
) {
  return configs.map((config) => SubResourceTab(config, props));
};

const GenericShow = function GenericShow(config) {
  const subResourceConfigs = config.sub_resources ? config.sub_resources : [];
  const ExternalIdMappingTabComponent = ExternalIdMappingTab(config);

  return function ShowComponent(props) {
    return (
      <Show {...props} title={<ShowTitle config={config} />}>
        <ParentRecordContextProvider resourceIdKey={config.resource_id_key}>
          <TabbedShowLayout
            tabs={
              <TabbedShowLayoutTabs
                variant="scrollable"
                scrollButtons="auto"
                {...props}
              />
            }
          >
            <Tab label="summary">
              {config.attributes.map((attribute) =>
                Attribute("Show", attribute, "showPage")
              )}
            </Tab>
            {SubResourceTabComponents(subResourceConfigs, props)}
            {config.mapped_to && (
              <Tab label="External IDs">
                <ExternalIdMappingTabComponent />
              </Tab>
            )}
          </TabbedShowLayout>
        </ParentRecordContextProvider>
      </Show>
    );
  };
};

export default GenericShow;
