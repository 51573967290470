import { React } from "react";

import {
  useNotify,
  useDataProvider,
  useRefresh,
  RecordContextProvider,
  SaveContextProvider,
  SimpleForm,
  CheckboxGroupInput,
  SaveButton,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useRecordContext } from "react-admin";

const BulkSelectButtons = ({ choices }) => {
  const { setValue } = useFormContext();
  const handleUnselectClick = (e) => {
    e.preventDefault();
    setValue("selected_plans", []);
  };
  const handleSelectClick = (e) => {
    e.preventDefault();
    const selectAll = choices.map((choice) => choice.id);
    setValue("selected_plans", selectAll);
  };

  return (
    <div>
      <button style={{ marginRight: "5px" }} onClick={handleSelectClick}>
        Select all
      </button>
      <button onClick={handleUnselectClick}>Unselect all</button>
    </div>
  );
};

const SelectPlansToQuotePrompt = function SelectPlansToQuotePrompt({ data }) {
  const {
    default_sage_plans_in_quote: defaultPlans,
    additional_sage_plans_in_quote: additionalPlans,
    sage_to_zywave_plan_mapping: planMapping,
  } = data;

  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { takeAction } = useDataProvider();
  const mutationMode = "pessimistic";

  const planNameRenderer = (plan) =>
    `${plan.provider_name} ${plan.display_name} ${plan.name}`;

  const { mutate, isLoading } = useMutation({
    mutationFn: (formRecord) => {
      var selectedPlanMappings = {};
      formRecord.selected_plans.forEach((planId) => {
        selectedPlanMappings[planId] = planMapping[planId];
      });
      takeAction(
        "company_insurance_quote_requests",
        { id: record.owner_id, action: "map_zywave_plans" },
        {
          body: JSON.stringify({
            plan_mappings: selectedPlanMappings,
            workflow_run_id: record.id,
          }),
        }
      ).then(
        () => {
          refresh();
          notify("Plans mapped!", { type: "success" });
        },
        (error) => {
          notify(error.body.error, { type: "error", autoHideDuration: 10000 });
        }
      );
    },
  });

  const choices = defaultPlans.concat(additionalPlans);

  return (
    <div>
      <RecordContextProvider>
        <SaveContextProvider
          value={{ save: mutate, saving: isLoading, mutationMode }}
        >
          <SimpleForm toolbar={false} id="plan_mappings_form">
            <h4>Select Plans</h4>
            <p>
              Not seeing the plans you expect? Go to the quote url on the right
              hand side and hit "Check for additional plans".
            </p>
            <BulkSelectButtons choices={choices} />
            <CheckboxGroupInput
              source="selected_plans"
              choices={choices}
              defaultValue={defaultPlans.map((plan) => plan.id)}
              optionText={planNameRenderer}
              row={false}
            />
            <SaveButton
              type="save"
              sx={{ fontWeight: "bold", marginTop: "10px" }}
              label={"Save"}
              alwaysEnable
            />
          </SimpleForm>
        </SaveContextProvider>
      </RecordContextProvider>
    </div>
  );
};

export default SelectPlansToQuotePrompt;
