import { React } from "react";
import PropTypes from "prop-types";
import { Datagrid, NumberField, TextField } from "react-admin";

const sort = { field: "age", order: "ASC" };

const AgeBandedDataGrid = function ({ rates }) {
  // convert the {1: $1200, 2: $1300 ...} shaped object into an array of
  // {age: 1, rate: $1200} shaped objects so that we get a tall table that is
  // easier to see on an array of screensizes
  const rotatedRates = Object.keys(rates).map((age) => ({
    age,
    rate: rates[age],
  }));
  return (
    <Datagrid
      sx={{ maxWidth: 250 }}
      size="small"
      data={rotatedRates}
      sort={sort}
      bulkActionButtons={false}
      rowClick={false}
    >
      <TextField source="age" />
      <NumberField
        source="rate"
        options={{ style: "currency", currency: "USD" }}
      />
    </Datagrid>
  );
};

AgeBandedDataGrid.propTypes = {
  rates: PropTypes.objectOf(PropTypes.number).isRequired,
};

export default AgeBandedDataGrid;
