import { fetchUtils } from "ra-core";
import { oktaAuth } from "lib/SsoAuthProvider";

/*
 * Process the response by adding the count to the header value react admin
 * expects and shifting the data up as the top level value.
 *
 * For implementation of fetchJson see:
 * https://github.com/marmelab/react-admin/blob/dc5f6abf6f0c5a67a288db6718330f2cb2148abb/packages/ra-core/src/dataProvider/fetch.ts#L30
 */
const { REACT_APP_INTERNAL_TOOLS_ENABLE_AUTHENTICATION } = process.env;
const authEnabled = REACT_APP_INTERNAL_TOOLS_ENABLE_AUTHENTICATION !== "false";

const generateContentRange = ({ unit, range, total }) => {
  const [rangeStart, rangeEnd] = range;

  return `${unit} ${rangeStart}-${rangeEnd}/${total}`;
};

const SsoHttpClient = async (url, userOpts = {}) => {
  const options = { credentials: "include", ...userOpts };

  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  } else {
    options.headers = new Headers(options.headers);
  }
  options.headers.set("Content-Type", "application/json");

  if (authEnabled) {
    const { accessToken } =
      (await oktaAuth.tokenManager?.get("accessToken")) || {};
    options.headers.set("Authorization", `Bearer ${accessToken}`);
  }

  return fetchUtils
    .fetchJson(url, options)
    .then(({ status, headers, body, json }) => {
      const newHeaders = new Headers(headers);
      if (json.meta?.count >= 0) {
        newHeaders.set("Content-Range", generateContentRange(json.meta));
      }
      const { data } = json;
      return {
        status,
        body,
        json: data || json,
        headers: newHeaders,
      };
    });
};

export default SsoHttpClient;
