/* eslint-disable react-hooks/exhaustive-deps */
/*
  This component is a generic form for creating records based on a schema.
  It takes in several arguments:
  - schema: an object that describes the properties of the record to be created
  - propertiesToIgnore: a list of properties that should not be included in the form
  - presetFormValues: a list of values that should be pre-filled in the form (not editable)
  - baseApiUrl: the base URL for the API which will be used to fetch associationed records to populate dropdowns
  - createUrl: the URL to send the POST request to
  - onSuccess: a callback function to be called after the form is successfully submitted
  - sx: custom styling for the form
*/
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  formFieldType,
  formFieldLabel,
  formDropdownOptions,
  checkboxLabel,
} from "./schemaHelpers";
import dayjs from "dayjs";
import omit from "lodash/omit";
import { HttpClient } from "lib/HttpClient";

function EntityForm({
  schema,
  propertiesToIgnore,
  presetFormValues,
  baseApiUrl,
  createUrl,
  onSuccess,
  sx,
}) {
  // Prefill form with default values
  const createFormProperties = omit(schema.properties, propertiesToIgnore);
  const prefilledForm = {
    ...Object.keys(createFormProperties).reduce((acc, key) => {
      acc[key] = createFormProperties[key].format === "date" ? null : "";
      return acc;
    }, {}),
    ...presetFormValues,
  };

  // State
  const [formState, setState] = useState(prefilledForm);
  const [dropDownAndCheckboxOptions, setDropdownAndCheckboxOptions] = useState(
    {}
  );
  const [submissionError, setSubmissionError] = useState(null);

  // Data update handlers
  const handleTextOrAssociationUpdate = (key) => (event) => {
    setState((prevState) => ({ ...prevState, [key]: event.target.value }));
  };
  const handleDateUpdate = (key) => (newDate) => {
    const formattedDate = newDate ? newDate.format("YYYY-MM-DD") : null;
    setState((prevState) => ({ ...prevState, [key]: formattedDate }));
  };
  const handleSubmit = async () => {
    const updatedformState = formatFormState(formState);
    await sendFormData(createUrl, updatedformState);
    setState(prefilledForm);
    onSuccess();
  };
  const handleCheckboxUpdate = (key) => (event) => {
    if (event.target.checked) {
      setState((prevState) => ({
        ...prevState,
        [key]: [...prevState[key], event.target.defaultValue],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [key]: prevState[key].filter(
          (val) => val !== event.target.defaultValue
        ),
      }));
    }
  };
  const sendFormData = async (url, data) => {
    try {
      const { json } = await HttpClient(url, {
        method: "POST",
        body: JSON.stringify(data),
      });
      setSubmissionError(null);
      return json;
    } catch (error) {
      setSubmissionError(error);
    }
  };
  const formatFormState = (formState) => {
    const updatedformState = Object.keys(formState).reduce((acc, key) => {
      acc[key] = formState[key] === "" ? null : formState[key];
      return acc;
    }, {});
    return updatedformState;
  };

  useEffect(() => {
    async function getDropdownOptions() {
      Object.entries(createFormProperties).forEach(
        ([propertyName, propertySchemaConfig]) => {
          formDropdownOptions(propertySchemaConfig, baseApiUrl).then(
            (options) => {
              setDropdownAndCheckboxOptions((prevState) => ({
                ...prevState,
                [propertyName]: options,
              }));
            }
          );
        }
      );
    }
    setState(prefilledForm);
    getDropdownOptions();
  }, [baseApiUrl, schema]);

  const formField = (propertyName, propertyType) => {
    switch (formFieldType(propertyType)) {
      case "dropdown":
        return (
          <DropdownFormField
            propertyName={propertyName}
            propertyType={propertyType}
          />
        );
      case "datePicker":
        return (
          <DateFormField
            propertyName={propertyName}
            propertyType={propertyType}
          />
        );
      case "checkbox":
        return (
          <CheckBoxField
            propertyName={propertyName}
            propertyType={propertyType}
          />
        );

      default:
        return (
          <TextField
            label={formFieldLabel(propertyName, propertyType) || propertyName}
            value={formState[propertyName]}
            disabled={presetFormValues[propertyName] !== undefined}
            onChange={handleTextOrAssociationUpdate(propertyName)}
            required={schema.required.includes(propertyName)}
            sx={{ width: "100%" }}
          />
        );
    }
  };

  const DropdownFormField = ({ propertyName, propertyType }) => {
    return (
      <TextField
        label={formFieldLabel(propertyName, propertyType) || propertyName}
        select
        value={formState[propertyName]}
        disabled={presetFormValues[propertyName] !== undefined}
        onChange={handleTextOrAssociationUpdate(propertyName)}
        required={schema.required.includes(propertyName)}
        sx={{ width: "100%" }}
      >
        {(dropDownAndCheckboxOptions[propertyName] || []).map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const CheckBoxField = ({ propertyName, propertyType }) => (
    <div>
      <h3>{formFieldLabel(propertyName, propertyType)}</h3>
      <FormGroup
        row
        value={formState[propertyName]}
        onChange={handleCheckboxUpdate(propertyName)}
      >
        {(dropDownAndCheckboxOptions[propertyName] || []).map((option) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={formState[propertyName]?.includes(option.id)}
              />
            }
            label={checkboxLabel(option)}
            key={option.id}
            value={option.id}
          />
        ))}
      </FormGroup>
    </div>
  );

  const DateFormField = ({ propertyName, propertyType }) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={formFieldLabel(propertyName, propertyType) || propertyName}
        value={formState[propertyName] ? dayjs(formState[propertyName]) : null}
        onChange={handleDateUpdate(propertyName)}
        slotProps={{
          textField: {
            required: schema.required.includes(propertyName),
          },
        }}
        sx={{ width: "100%" }}
      />
    </LocalizationProvider>
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        ...sx,
      }}
    >
      <h2>New Record</h2>
      {Object.entries(createFormProperties).map(
        ([propertyName, propertyType]) => (
          <div key={propertyName}>{formField(propertyName, propertyType)}</div>
        )
      )}
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
      {submissionError && (
        <p style={{ color: "red" }}>Error: {submissionError.body.error}</p>
      )}
    </Box>
  );
}

export default EntityForm;
