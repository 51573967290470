/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import {
  useNotify,
  useRefresh,
  useRecordContext,
  useUpdate,
  useCreate,
  RecordContextProvider,
  SaveContextProvider,
  SimpleForm,
  DateInput,
  Error,
  Loading,
  Title,
} from "react-admin";

import { Attribute } from "../../../../../GenericAttributes";
import { useGetHistory } from "../../../../../../../lib/Hooks";
import ShowTitle from "../../../../ShowTitle";

const HistoryEdit = function (config) {
  const {
    tab_name: resourceName,
    backend_resource: resource,
    linking_field: reference,
    attributes,
  } = config;

  const HistoryEditComponent = function HistoryEditComponent({ parentId }) {
    const selectedTimeLineIdentifiers = useRecordContext();
    const timelineAttributes = Object.keys(selectedTimeLineIdentifiers);
    const editableAttributes = attributes.filter(
      (attribute) => !timelineAttributes.includes(attribute.source)
    );
    const notify = useNotify();
    const refresh = useRefresh();
    const recordBase = {
      [reference]: parentId,
      ...selectedTimeLineIdentifiers,
    };

    const {
      data,
      isLoading: historyLoading,
      error: historyLoadError,
    } = useGetHistory(resource, { filter: recordBase });

    const history = data || [];
    const currentRecord = history[history.length - 1] || {};

    const [update, { isLoading: updateLoading }] = useUpdate();
    const [create, { isLoading: createLoading }] = useCreate();
    const saveFunction = currentRecord && currentRecord.id ? update : create;

    if (historyLoading) return <Loading />;
    if (historyLoadError) return <Error error={historyLoadError} />;

    const save = (formValues) => {
      saveFunction(
        resource,
        {
          id: currentRecord.id,
          data: { ...currentRecord, ...formValues, ...recordBase },
        },
        {
          onSuccess: () => {
            refresh();
            notify(`${resourceName} updated`, { type: "success" });
          },
          onError: (error) => {
            refresh();
            notify(error.message, { type: "error" });
          },
        }
      );
    };

    return (
      <RecordContextProvider value={currentRecord}>
        <SaveContextProvider
          value={{
            save,
            saving: updateLoading || createLoading,
            mutationMode: "pessimistic",
          }}
        >
          <Title title={<ShowTitle config={config} />} />
          <SimpleForm>
            {editableAttributes.map((attribute) =>
              Attribute("Create", attribute, "historyEdit")
            )}
            <DateInput source="start_date" label="Start Date" />
            <DateInput source="end_date" label="End Date" />
          </SimpleForm>
        </SaveContextProvider>
      </RecordContextProvider>
    );
  };

  return HistoryEditComponent;
};

export default HistoryEdit;
