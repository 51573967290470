import { React } from "react";

import {
  Show,
  List,
  Datagrid,
  EditButton,
  DeleteWithConfirmButton,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
} from "react-admin";
import { DocumentUploadButton } from "../../DocumentTab/DocumentUploadButton";
import { DocumentDownloadButton } from "../../DocumentTab/DocumentDownloadButton";
import { Attribute } from "components/Blueprint/GenericAttributes";

const InstallDocumentDownload = function InstallDocumentDownload() {
  const { document_id: id, file_name: fileName } = useRecordContext();

  return <DocumentDownloadButton documentId={id} fileName={fileName} />;
};

const InstallDocumentSubList = function InstallDocumentSubList() {
  const { id: companyId } = useRecordContext();
  const attributes = [
    {
      source: "name",
      label: "Company",
      reference: [["companies", "company_id"]],
      validations: { required: true },
    },
    {
      source: "label",
      label: "Document Type",
      reference: [
        [
          "company_carrier_install_document_types",
          "company_carrier_install_document_type_id",
        ],
      ],
      readonly: true,
    },
    { source: "category", label: "Category", readonly: true },
    { source: "approved", label: "Approved?", type: "boolean" },
  ];

  return (
    <List
      resource="company_carrier_install_documents"
      sort={{ field: "created_at", order: "ASC" }}
      perPage={25}
      empty={false}
      filter={{ company_id: companyId }}
      disableSyncWithLocation={true}
    >
      <Datagrid rowClick={false}>
        {attributes.map((attribute) => Attribute("List", attribute))}
        <InstallDocumentDownload />
        <EditButton />
        <DeleteWithConfirmButton redirect={false} />
      </Datagrid>
    </List>
  );
};

const inputStyle = { marginBottom: "5px" };
const shouldRenderSuggestions = (val) => {
  const parsedValue = val.trim().length;
  return parsedValue > 2 || parsedValue === 0;
};

const CompanyCarrierInstallDocumentUploadComponent =
  function CompanyCarrierInstallDocumentUploadComponent() {
    const companyId = useRecordContext().id;
    const uploadRoute = "company_carrier_install_documents";

    return (
      <>
        <Show title=" > Upload">
          <DocumentUploadButton
            uploadRoute={uploadRoute}
            resourceId={companyId}
          >
            <ReferenceInput
              sort={{ field: "label", order: "ASC" }}
              key="company_carrier_install_document_type_id"
              label="Type"
              source="company_carrier_install_document_type_id"
              reference="company_carrier_install_document_types"
            >
              <AutocompleteInput
                label="Type"
                optionText="label"
                sx={inputStyle}
                filterToQuery={(query) => {
                  if (!query) return {};
                  return { query };
                }}
                shouldRenderSuggestions={shouldRenderSuggestions}
              />
            </ReferenceInput>
            <ReferenceInput
              key="company_carrier_install_document_category_id"
              label="Category"
              sort={{ field: "label", order: "ASC" }}
              source="company_carrier_install_document_category_id"
              reference="company_carrier_install_document_categories"
            >
              <AutocompleteInput
                label="Category"
                optionText="label"
                sx={inputStyle}
                filterToQuery={(query) => {
                  if (!query) return {};
                  return { query };
                }}
                shouldRenderSuggestions={shouldRenderSuggestions}
              />
            </ReferenceInput>
          </DocumentUploadButton>
        </Show>
        <InstallDocumentSubList />
      </>
    );
  };

const CompanyCarrierInstallDocumentUploadTab =
  function CompanyCarrierInstallDocumentUploadTab() {
    return (
      <CompanyCarrierInstallDocumentUploadComponent key="CompanyCarrierInstallDocumentUploadComponent" />
    );
  };

export default CompanyCarrierInstallDocumentUploadTab;
