import { React } from "react";
import PropTypes from "prop-types";
import { useNotify, useRedirect, useRefresh, Button } from "react-admin";
import { useTakeAction } from "../../../../lib/Hooks";

const ActionButton = function ActionButton({
  resource, // The resource for the action. This forms the first part of the URL.
  id, // The id of the object to take action on. This forms the second part of the URL.
  action, // The action name if applicable. This forms the optional third part of the URL.
  subAction, // The subaction name if applicable. This forms the optional fourth part of the URL.
  label, // The text label to display
  successMessage, // Optional flash message to display on success
  errorMessage, // Optional error message. Default is error message from the server.
  redirectUrl, // Optional redirect. By default the current view will be refreshed.
  callback, // Optional callback function that will be called with the response data.
  contentType, // Optional Content-Type override.
  headers, // Optional additional headers to be combined with defaults
  bodyData, // Optional body data string to be included with the request
  method, // HTTP Method to use. Can be PUT or POST. Default is POST.
  disabled,
}) {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const postHeaders = {
    ...headers,
    "Content-Type": contentType,
  };

  const [takeAction, { isLoading }] = useTakeAction(
    resource,
    { id, action, subAction },
    {
      body: JSON.stringify(bodyData),
      headers: postHeaders,
      method,
      onSuccess: (data) => {
        callback(data);
        if (redirectUrl) {
          redirect(redirectUrl);
        } else {
          refresh();
        }
        notify(successMessage, { type: "success" });
      },
      onError: (response) => {
        notify(errorMessage || response.message, { type: "error" });
      },
    }
  );

  return (
    <Button
      label={label}
      onClick={takeAction}
      disabled={isLoading || disabled}
    />
  );
};

ActionButton.propTypes = {
  resource: PropTypes.string.isRequired,
  id: PropTypes.string,
  action: PropTypes.string,
  subAction: PropTypes.string,
  label: PropTypes.string.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  redirectUrl: PropTypes.string,
  callback: PropTypes.func,
  contentType: PropTypes.string,
  headers: PropTypes.objectOf(PropTypes.string),
  bodyData: PropTypes.any,
  method: PropTypes.oneOf(["GET", "POST", "PUT"]),
  disabled: PropTypes.bool,
};

ActionButton.defaultProps = {
  id: undefined,
  action: undefined,
  subAction: undefined,
  successMessage: "Success!",
  errorMessage: undefined,
  redirectUrl: undefined,
  callback: () => {},
  contentType: "application/json",
  headers: {},
  bodyData: undefined,
  method: "POST",
  disabled: false,
};

export default ActionButton;
